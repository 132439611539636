import { isEqual } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';

import type { NavItem } from '@core/types';

// Services
import { NavigationBuilderService } from '@core/helpers/navigation-builder/navigation-builder.service';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  navigation: NavItem[] = [];

  constructor(
    private navigationBuilderService: NavigationBuilderService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this
      .navigationBuilderService
      .buildToolbarNavigation()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(this.renderNavigation.bind(this));
  }

  renderNavigation(navigation: NavItem[]): void {
    if (navigation && !isEqual(navigation, this.navigation)) {
      this.navigation = navigation;
    }
  }

}
