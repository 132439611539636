import { Observable, Subject } from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { withLatestFrom } from 'rxjs/operators';

import type { Profile, NavItem, CompanyDetails } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { CompaniesService } from '@core/services/details/companies/companies.service';

@UntilDestroy()
@Component({
  selector: 'profile-actions',
  templateUrl: './profile-actions.component.html',
  styleUrls: ['./profile-actions.component.scss'],
})
export class ProfileActionsComponent implements OnInit {

  @ViewChild('profileActionsDropdown', { static: true }) profileActionsDropdown: BsDropdownDirective;

  profile$: Observable<Profile> = this.userService.profile$;

  mobileMenuState: Subject<boolean> = new Subject<boolean>();

  nav: NavItem[] = [];

  @HostListener('click') toggle(): void {
    this.profileActionsDropdown.toggle(true);
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.profileActionsDropdown.toggle(false);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private userService: UserService,
    private companiesService: CompaniesService,
  ) {}

  ngOnInit(): void {}

  logout(): void {
    this
      .userService
      .logout(true)
      .pipe(
        withLatestFrom(this.companiesService.companyDetails$),
        untilDestroyed(this),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([response, { code }]: [any, CompanyDetails]) => {
        this.router.navigate([code ? `${ROUTE.alias.SIGN_IN}/${code}` : ROUTE.alias.SIGN_IN]);
      });
  }

}
